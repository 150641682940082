import FetchRequest from "@/api/FetchRequest";
class CaptionApi {
    //基础url
    static url = "/api/audio/";
    /**
   * 获取上传列表
   * @param state 状态
   * @param  pageNum 当前页数
   * @param  pageSize 每页多少条
   */
    static getList( pageNum: number, pageSize: number,title?: string): Promise<any> {

        return FetchRequest.get(this.url + 'text/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&title=' + title, true);
    }
    /**
      * 获取详细信息
      * 
      * 
      */
    static getTextInfo(id: string): Promise<any> {
        return FetchRequest.get(this.url + 'text' + '/' + id, true);
    }
    // 导出字幕
    static TextDownlod(id: string,contents: string,ext: string): Promise<any> {
        const data={
            id:id,
            contents:'',
            ext:''
        }
        return FetchRequest.get(this.url + 'text/export'+'?id='+id +'&contents='+contents+'&ext='+ext,true,true);
    }
    /**
      * 上传文件新增
      * @param  fileId 上传文件ID
      * 
      */
    static UpDownlod(fileId: string): Promise<any> {
        const data={
            fileId:fileId
        }
        return FetchRequest.post(this.url + 'text' ,JSON.stringify(data) , true);
    }
    // 保存
    /**
      * 上传文件新增
      * @param  fileId 上传文件ID
      * @param  finalText 字幕
      */
    static Save(id: string,finalText:[]): Promise<any> {
        const data={
            id:id,
            finalText:finalText
           
        }
        return FetchRequest.put(this.url + 'text' ,JSON.stringify(data) , true);
    }
    static SaveRoles(id: string,roles:[]): Promise<any> {
        const data={
            id:id,
            roles:roles
           
        }
        return FetchRequest.put(this.url + 'text' ,JSON.stringify(data) , true);
    }
    static SaveRule(id: string,checkRule={}): Promise<any> {
        const data={
            id:id,
            checkRule:checkRule
        }
        return FetchRequest.put(this.url + 'text' ,JSON.stringify(data) , true);
    }
    // 简繁转换
    /**
      * 上传文件新增
      * @param  fileId 上传文件ID
      * @param  finalText 字幕
      */
    static textConvert(id: string): Promise<any> {
       
        return FetchRequest.post(this.url + 'text/'+id , '', true);
    }

    // 音视频识别字幕
    static aiToText(id: string,language:string,hotWord:string): Promise<any> {
        const data={
            hotWord: hotWord,
            language: language
        }
        return FetchRequest.post(this.url + 'text/audioToText/'+id ,JSON.stringify(data) , true);
    }
    // 删除音视频记录
    static delText(id: string): Promise<any> {
       
        return FetchRequest.del(this.url + 'text/'+id,'' , true);
    }
    // 删除译文
    static delTextEn(id: string): Promise<any> {
       
        return FetchRequest.del(this.url + 'text/translation/'+id,'' , true);
    }
    // 智能翻译
    static addTextEn(id: string,to:string,from:string): Promise<any> {
        const data={
            to: to,
            from: from
        }
        return FetchRequest.post(this.url + 'text/translate/'+id+'?to='+to+'&from='+from,'' , true);
    }
    // 获取音轨峰值
    static getwave(id: string,samplesPerPixel: string,startSample: string,endSample: string): Promise<any> {
      
        return FetchRequest.get(this.url + 'text/wave/'+id +'?samplesPerPixel='+samplesPerPixel+'&startSample='+startSample+'&endSample='+endSample,true,false);
    }
}
export default CaptionApi;